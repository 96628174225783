import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_11/subSlide';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(
        frontmatter: { language: { eq: "DE" }, title: { eq: "4-11-9-mackiewicz" } }
      ) {
        body
      }
      file(relativePath: { eq: "chapter_4/sub_11/4-11-9-mackiewicz.png" }) {
        childImageSharp {
          fixed(width: 230) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      }
    }
  `);
  return <Template query={query} />;
};

export default Slide;
