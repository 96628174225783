import React from 'react';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/de/chapter_4/sub_11/slide1';
import Slide2 from 'src/slides/desktop/de/chapter_4/sub_11/slide2';
import Slide3 from 'src/slides/desktop/de/chapter_4/sub_11/slide3';
import Slide4 from 'src/slides/desktop/de/chapter_4/sub_11/slide4';
import Slide5 from 'src/slides/desktop/de/chapter_4/sub_11/slide5';
import Slide6 from 'src/slides/desktop/de/chapter_4/sub_11/slide6';
import Slide7 from 'src/slides/desktop/de/chapter_4/sub_11/slide7';
import Slide8 from 'src/slides/desktop/de/chapter_4/sub_11/slide8';
import Slide9 from 'src/slides/desktop/de/chapter_4/sub_11/slide9';
import Slide10 from 'src/slides/desktop/de/chapter_4/sub_11/slide10';
import Slide11 from 'src/slides/desktop/de/chapter_4/sub_11/slide11';
import Slide12 from 'src/slides/desktop/de/chapter_4/sub_11/slide12';
import Slide14 from 'src/slides/desktop/de/chapter_4/sub_11/slide14';
import Slide15 from 'src/slides/desktop/de/chapter_4/sub_11/slide15';
import Slide16 from 'src/slides/desktop/de/chapter_4/sub_11/slide16';

import WrapperMobile from 'src/slides/mobile/de/chapter_4/sub_11/wrapperMobile';

import { isMobile } from 'react-device-detect';
// import Slide
import SEO from 'src/components/_shared/seo';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider';

const allSlides = [<Slide1 />, <Slide2 />, <Slide3 />, <Slide4 />, <Slide5 />, <Slide6 />, <Slide7 />, <Slide8 />, <Slide9 />, <Slide10 />, <Slide11 />, <Slide12 />, <Slide14 />, <Slide15 />, <Slide16 />];

const exceptions = [7, 8];

const Desktop = () => (
  <>
    <SEO
      title="Die Propaganda des polnisch-sowjetischen Krieges | Schlacht bei Warschau"
      description="Die fetten Bourgeois und Affenmenschen aus dem Osten oder die Kunst der Propaganda um 1920."
      lang="de"
    />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO
      title="Die Propaganda des polnisch-sowjetischen Krieges | Schlacht bei Warschau"
      description="Die fetten Bourgeois und Affenmenschen aus dem Osten oder die Kunst der Propaganda um 1920."
      lang="de"
    />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
