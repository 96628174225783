import React from 'react';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_11/slide12';

import { graphql, useStaticQuery } from 'gatsby';

const Slide = () => {
  const query = useStaticQuery(graphql`
      query {
        mdx(
          frontmatter: { language: { eq: "DE" }, title: { eq: "slide-4-11-12" } }
        ) {
          body
          frontmatter {
            alt
          }
        }
      }
    `);
  return <Template query={query} />;
};

export default Slide;
